<route>
{
  "meta": {
    "auth": "storeEdit"
  }
}
</route>

<template>
  <div>
    <el-card class="addCommentContainer">
      <el-form ref="form" class="vendorFrom" label-position="right" :model="form" label-width="10em" :rules="rules">
        <div class="secondTitle title">基础信息</div>
        <el-form-item label="所属商家">
          <span style="font-weight: bold">{{ $store.getters['vendor/params/getVendorName'] }}</span>
        </el-form-item>

        <el-form-item label="门店名称" prop="storeTitle">
          <span v-if="check">{{ form.storeTitle }}</span>
          <span v-else>
            <el-input v-model.trim="form.storeTitle" placeholder="请输入商家名(门店名)"></el-input>
            <span class="assistText">门店名称例子：海底捞(琶洲店)</span>
          </span>
        </el-form-item>
        <el-form-item label="是否显示">
          <span v-if="check">{{ form.hideStatus ? '不显示' : '显示' }}</span>
          <el-switch v-else v-model="form.hideStatus" :active-value="0" :inactive-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="门店LOGO" prop="storeLogo">
          <img class="img" v-if="check" :src="form.storeLogo" />
          <bm-upload v-else type="system" v-model="form.storeLogo"></bm-upload>
        </el-form-item>

        <el-form-item label="门店环境图" prop="storeEnvironmentImages">
          <span v-if="check">
            <img class="img" v-for="item in form.storeEnvironmentImages" :src="item" :key="item" />
          </span>
          <bm-upload
            v-else
            type="system"
            v-model="form.storeEnvironmentImages"
            :format="imageAccept"
            :limit="1000"
          ></bm-upload>
        </el-form-item>

        <el-form-item label="联系电话" prop="storeTelephone">
          <div v-for="(item, index) in arr" :key="index">
            <el-input
              style="margin: 8px 0"
              placeholder="请输入电话"
              oninput="value=value.replace(/[^0-9-+]/g,'')"
              v-model="item.phone"
              @blur="validatePhone"
              :disabled="check"
            >
            </el-input>
            <el-button
              :disabled="check"
              type="danger"
              style="margin-left: 6px"
              v-if="index !== 0"
              @click="deletePhone(index)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>

          <div v-if="!check">
            <el-button type="primary" v-show="arr.length < 3" @click="addPhone">添加电话号码 </el-button>
          </div>
        </el-form-item>
        <el-form-item label="门店地址">
          <span v-if="check">{{ form.storeAddress }}</span>

          <span v-else>
            <el-input placeholder="请选择门店地址" v-model.trim="form.storeAddress"> </el-input>
            <el-button type="primary" class="selectLocation" @click.native="isShowMapPicker = true"
              >选择定位
            </el-button>
          </span>
        </el-form-item>

        <el-form-item label="每周营业时间" prop="openingHours">
          <span v-if="check">{{ form.openingHours }}</span>
          <el-input v-else placeholder="请输入营业时间" v-model="form.openingHours" maxlength="64"> </el-input>
          <span class="assistText">模板： 周一 至 周五 上午9:00 至 下午 10:00</span>
        </el-form-item>
        <el-form-item label="海报二维码类型" prop="posterQrcodeType">
          <el-radio-group v-model="form.posterQrcodeType">
            <el-radio :disabled="check" :label="1">公众号码</el-radio>
            <el-radio :disabled="check" :label="2">小程序码</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="地区" prop="areaId">
          <el-select
            :disabled="check || isEdit"
            @change="handleChangeArea"
            v-model="form.areaId"
            placeholder="请选择城市"
          >
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="关联商圈" required>
          <el-cascader
            :disabled="check"
            :options="businessDistrictList"
            v-model="form.businessDistrictIds"
            @change="changeVal"
            :props="props"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item label="关联标签" prop="storeTagList" v-if="!check">
          <el-button type="primary" @click="selectTagDialog = true">选择标签</el-button
          ><span class="assistText">添加门店服务设施，提供给客户参考</span>
        </el-form-item>
        <el-form-item label="已关联标签">
          <el-tag
            type="success"
            :key="item.id + 'formTag'"
            class="selectTag"
            v-for="(item, index) in form.storeTagList"
            :closable="!check"
            :disable-transitions="false"
            @close="handleClose(index)"
          >
            {{ item.tagName }}
          </el-tag>
        </el-form-item>

        <div class="secondTitle title">核销信息</div>

        <el-form-item label="核销验证码" prop="exchangeCheckCode">
          <span v-if="check">{{ form.exchangeCheckCode }}</span>
          <span v-else>
            <el-input
              v-model.trim="form.exchangeCheckCode"
              @blur="handleChangeCode"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder="请输入核销验证码"
              style="margin-right: 10px"
            ></el-input>
            <el-button type="primary" @click="generateCode">随机生成</el-button></span
          >
        </el-form-item>

        <el-form-item label="" v-if="!check">
          <span class="assistText"
            >须是4位数字的格式，按此方式不区分具体核销人员，只区分门店， 且各门店核销码不一样，请根据实际使用</span
          >
        </el-form-item>

        <div class="secondTitle title" v-if="isShowSubMer">二级商户号</div>

        <!-- <el-form-item label="是否关联二级商户号">
          <span v-if="check">{{ form.bindedSubMerchant ? '关联' : '不关联' }}</span>
          <el-switch v-else v-model="form.bindedSubMerchant" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item> -->

        <el-form-item label="关联二级商户号" v-if="isShowSubMer">
          <el-select
            class="searchInput"
            :disabled="check || disabledSubMer"
            @change="handleShareStatus"
            v-model="form.subMchid"
            placeholder="请选择"
          >
            <el-option
              v-for="item in subMChildOptions"
              :key="item.subMchid"
              :label="item.accountName"
              :value="item.subMchid"
              :disabled="item.disabled"
            >
              <span style="float: left">{{ item.accountName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.remark }}</span>
            </el-option>
          </el-select>

          <el-tag style="margin-left: 8px" type="primary" v-if="status === 1">取用</el-tag>
          <el-tag style="margin-left: 8px" type="success" v-else-if="status === 0">自有</el-tag>
          <div v-else></div>
        </el-form-item>

        <el-form-item v-if="!check">
          <el-button type="primary" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- ========================选择标签============= -->
    <el-dialog
      :close-on-click-modal="false"
      title="选择标签"
      :visible.sync="selectTagDialog"
      width="30%"
      class="tagDialog"
    >
      <div class="tip">
        请选择门店对应的设施服务停车场
        <span class="assist"> 注*：门店的设施服务可多选</span>
      </div>

      <el-tag
        :key="item.id"
        v-for="item in storeTag"
        closable
        class="tag"
        @click="selectTag(item)"
        :disable-transitions="false"
        @close="handleDelete(item)"
      >
        {{ item.tagName }}
      </el-tag>

      <div class="selectServe">
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          type="success"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
        >
        </el-input>
        <el-button v-if="inputVisible" class="button-new-tag" size="small" @click="handleInputConfirm">确定</el-button>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">添加服务标签</el-button>
      </div>

      <div class="selectServe">您当前选择的设施服务有：</div>
      <el-tag
        :key="item.id + 'select'"
        class="tag"
        v-for="(item, index) in form.storeTagList"
        closable
        :disable-transitions="false"
        type="success"
        @close="handleClose(index)"
      >
        {{ item.tagName }}
      </el-tag>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="selectTagDialog = false">关闭选择服务标签弹窗</el-button>
      </span>
    </el-dialog>

    <bm-mapPicker v-model="isShowMapPicker" @success="onPickMapSuccess"></bm-mapPicker>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    editor: Editor
  },
  data() {
    return {
      disabledSubMer: false,
      businessDistrictList: [],
      props: {
        multiple: true,
        value: 'id',
        label: 'districtTitle',
        children: 'child'
      },
      inputValue: '',
      selectTagDialog: false,
      inputVisible: false,
      storeTag: [],
      isShowMapPicker: false,
      status: -1,
      arr: [{ phone: '' }],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      subMChildOptions: [],
      check: false,
      form: {
        areaId: '',
        openingHours: '',
        posterQrcodeType: 1,
        hideStatus: 0,
        lat: 0,
        lon: 0,
        storeAddress: '',
        storeTitle: '',
        storeTelephone: '',
        storeLogo: '',
        businessDistrictIds: [],
        businessDistrictVOs: [],
        storeEnvironmentImages: [],
        exchangeCheckCode: '',
        bindedSubMerchant: 0,
        storeTagList: [],
        subMchid: ''
      },
      imageAccept: ['png', 'jpg', 'jpeg', 'bmp'],
      rules: {},
      areaList: [],
      isShowSubMer: false
    }
  },
  computed: {
    cols() {
      let cols = Object.keys(this.reserve.cols)
      cols.splice(cols.length - 1, 1)
      return cols
    },
    ...mapGetters({
      isEdit: 'vendor/params/isEdit'
    })
  },
  created() {
    this.getRules()
    this.getDetail()
    this.getDistrict()
    this.subMerchant()
    this.getTags()

    if (this.$store.getters['vendor/params/getStoreCheck']) {
      this.check = true
      this.rules = {}
    }
  },
  methods: {
    changeVal(val) {
      this.form.businessDistrictIds = val
      this.$forceUpdate()
    },
    handleShareStatus(val) {
      this.subMChildOptions.forEach(item => {
        if (item.subMchid === val) this.status = item.shareStatus
      })
    },
    validatePhone() {
      let allPhoneText = ''
      this.arr.forEach(item => {
        allPhoneText = allPhoneText + item.phone + ','
      })
      this.form.storeTelephone = allPhoneText.slice(0, -1)
    },
    handleClose(index) {
      this.form.storeTagList.splice(index, 1)
    },
    selectTag(item) {
      if (this.form.storeTagList.length !== 0) {
        let isRepeat = true
        this.form.storeTagList.forEach(i => {
          if (item.id === i.id) {
            isRepeat = false
          }
        })
        if (isRepeat) {
          let obj = {}
          obj.id = item.id
          obj.tagName = item.tagName
          this.form.storeTagList.push(obj)
        }
      } else {
        this.form.storeTagList.push({ id: item.id, tagName: item.tagName })
      }
    },
    handleDelete(item) {
      this.$http
        .delete('boom-center-product-service/sysAdmin/storeTag/' + item.id)
        .then(res => {
          this.$message.success('删除标签成功')
          this.getTags()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    showInput() {
      this.inputVisible = true
    },
    handleInputConfirm() {
      this.$api.vendor
        .vendor('postStoreTag', { tagName: this.inputValue })
        .then(res => {
          this.inputVisible = false
          this.$message.success('添加标签成功')
          this.inputValue = ''
          this.getTags()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    onPickMapSuccess(val) {
      this.form.lat = val.lat
      this.form.lon = val.lng
      this.form.storeAddress = val.address
    },
    getTags() {
      this.$api.vendor
        .vendor('getStoreTagList', {
          params: {
            size: 1000
          }
        })
        .then(res => {
          this.storeTag = res.list
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    subMerchant() {
      this.$api.vendor
        .vendor('getSubMerchant', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'] }
        })
        .then(res => {
          this.subMChildOptions = []
          let disabled = this.form.bindedSubMerchant ? true : false
          if (res.length) {
            res.unshift({
              outRequestNo: -1,
              applymentState: 6,
              accountName: '暂不绑定二级商户号',
              remark: '',
              id: 0,
              subMchid: '0',

              shareStatus: -1,
              disabled: disabled
            })

            this.subMChildOptions = res
          }
        })
        .catch(err => {
          this.subMChildOptions = []
          this.$message.error(err.msg || '操作失败')
        })
    },
    getRules() {
      this.rules = this.$initRules([
        {
          label: '门店名称',
          value: 'storeTitle',
          type: 'input',
          required: true
        },
        {
          label: '联系电话',
          value: 'storeTelephone',
          type: 'input',
          required: true
        },
        // {
        //   label: '商圈',
        //   value: 'businessDistrictIds',
        //   type: 'select',
        //   required: true
        // },

        {
          label: '地区',
          value: 'areaId',
          type: 'select',
          required: true
        },

        {
          label: '服务标签',
          value: 'storeTagList',
          type: 'select',
          required: true
        },
        {
          label: '每周营业时间',
          value: 'openingHours',
          type: 'input',
          required: true
        },
        {
          label: '海报二维码类型',
          value: 'posterQrcodeType',
          type: 'select',
          required: true
        },

        {
          label: '门店LOGO',
          value: 'storeLogo',
          type: 'upload',
          required: true
        },
        {
          label: '门店环境图',
          value: 'storeEnvironmentImages',
          type: 'upload',
          required: true
        }
      ])
    },
    deletePhone(index) {
      if (this.arr.length === 1) {
        this.$message.error('最少需要一个电话号码')
        return
      }
      this.arr.splice(index, 1)
    },
    addPhone() {
      if (this.arr.length >= 3) {
        this.$message.error('最多添加三个电话号码')
        return
      }
      this.arr.push({ phone: '' })
    },
    handleChangeCode() {
      this.$api.vendor
        .vendor('storeCheckExCode', {
          params: {
            businessId: this.$store.getters['vendor/params/getVendorId'],
            exchangeCheckCode: this.form.exchangeCheckCode,
            storeId:
              this.$store.getters['vendor/params/getStoreId'] !== '-1'
                ? this.$store.getters['vendor/params/getStoreId']
                : 0
          }
        })
        .then(res => {
          if (res.state == 0) {
            this.form.exchangeCheckCode = ''
            this.$message.error(err.msg || '验证码已经被使用')
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '验证码已经被使用')
        })
    },

    async getDistrict() {
      await this.$api.vendor.vendor('getBusinessDetail', this.$store.getters['vendor/params/getVendorId']).then(res => {
        this.isShowSubMer = true
        if (res.businessModel === 1) {
          this.isShowSubMer = false
        } else {
          if (res.areaIds.length === 1) {
            this.isShowSubMer = true
          } else {
            // 多地区
            if (this.$store.getters['vendor/params/getStoreId'] == -1) {
              // 新增
              this.isShowSubMer = false
            } else {
              //编辑
              this.isShowSubMer = true
              this.disabledSubMer = true
            }
          }
        }
        this.$store.getters['user/userAreaInfoListWithoutGlobal'].forEach(item => {
          if (res.areaIds.includes(item.id)) {
            this.areaList.push(item)
          }
        })
        return
      })
    },

    handleChangeArea(val) {
      this.$api.vendor
        .vendor('businessDistrictAll', {
          headers: {
            ['Bm-Area-Id']: val
          }
        })
        .then(res => {
          this.businessDistrictList = []
          res.map(i => {
            this.businessDistrictList = this.parseDistrict(res)
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    parseDistrict(list) {
      list.map(item => {
        item.value = item.id
        item.label = item.districtTitle
        if (item.child && item.child.length > 0) {
          item.child = this.parseDistrict(item.child)
        } else {
          item.child = undefined
        }
      })
      return list
    },

    generateCode() {
      //随机生成核销的码
      this.form.exchangeCheckCode = Math.random().toString().slice(3, 7)

      this.handleChangeCode()
    },
    getDetail() {
      //获取
      if (this.$store.getters['vendor/params/getStoreId'] !== '-1') {
        this.$api.vendor
          .vendor('getStoreDetail', this.$store.getters['vendor/params/getStoreId'])
          .then(res => {
            this.form = res
            this.arr = []
            let value = res.storeTelephone.split(',')
            res.storeTelephone.split(',').forEach(item => {
              this.arr.push({ phone: item })
            })
            this.subMChildOptions.forEach(item => {
              if (item.subMchid === res.subMchid) this.status = item.shareStatus
            })

            this.handleChangeArea(res.areaId)
            if (this.subMChildOptions.length) {
              let disabled = this.form.bindedSubMerchant ? true : false
              this.$set(this.subMChildOptions[0], 'disabled', disabled)
            }

            this.form.businessDistrictIds = []
            try {
              this.form.businessDistrictVOs.forEach(item => {
                let arr = []
                if (item.parent) {
                  arr.push(item.parent.id)
                }
                arr.push(item.id)
                this.form.businessDistrictIds.push(arr)
              })

              console.log(this.form.businessDistrictIds)
            } catch (e) {
              console.log('>>>>>>>>>> e', e)
            }
          })
          .catch(err => {
            console.log(err)
            this.$message.error(err.msg || '操作失败')
          })
      }
    },

    submit() {
      for (let i = 0; i < this.arr.length; i++) {
        if (this.arr[i].phone === '') {
          this.$message.error(`联系电话第${i + 1}项没有填入`)
          return
        }
      }

      let allPhoneText = ''
      this.arr.forEach(item => {
        allPhoneText = allPhoneText + item.phone + ','
      })
      this.form.storeTelephone = allPhoneText.slice(0, -1)

      this.$refs.form.validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return
        }

        let arr = []
        this.form.businessDistrictIds.forEach(item => {
          arr.push(item[item.length - 1])
        })
        this.form.businessDistrictIds = arr
        this.form.bindedSubMerchant = 0
        if (Number(this.form.subMchid) !== 0) {
          this.form.bindedSubMerchant = 1
        }

        let params = {
          ...this.form,
          businessId: this.$store.getters['vendor/params/getVendorId']
        }

        if (this.$store.getters['vendor/params/getStoreId'] === '-1') {
          const loading = this.$loading({
            lock: true,
            text: '正在提交，请稍候',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$api.vendor
            .vendor('postStore', {
              ...params,
              id: this.$store.getters['vendor/params/getStoreId']
            })
            .then(res => {
              this.$message.success('添加成功')
              this.$router.back()
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              loading.close()
            })
        } else {
          const loading = this.$loading({
            lock: true,
            text: '正在提交，请稍候',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$api.vendor
            .vendor('putStore', {
              ...params,
              id: this.$store.getters['vendor/params/getStoreId']
            })
            .then(res => {
              this.$message.success(`修改成功`)
              this.$router.back()
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(() => {
              loading.close()
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.period-col {
  background: rgba(33, 150, 243, 0.1) !important;
}
/deep/.el-table__header .col {
  background: rgba(231, 20, 26, 0.1) !important;
}
/deep/.special-col {
  background: rgba(231, 20, 26, 0.1) !important;
}
.addCommentContainer {
  margin: @container-margin;

  .vendorFrom {
    margin-left: 20px;
    .selectLocation {
      margin-left: 10px;
    }
    .title {
      margin: 20px 0;
    }
    .selectTag {
      margin: 10px;
    }

    .el-select,
    .el-cascader,
    .el-input {
      width: 300px;
    }
    .img {
      width: 100px;
      height: 100px;
      border-radius: 6px;
      margin-right: 10px;
    }
    .assist {
      .assistText;
    }
  }
  .tags {
    margin-top: 20px;
    .tag {
      margin-right: 10px;
    }
  }
}

.avatar-uploader {
  display: inline-flex;
  align-items: center;
}

.remove-btn {
  color: #e7141a;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
}
.input {
  width: fit-content !important;
}
.time-select {
  margin-right: 20px;
}
.actions {
  text-align: right;
  margin-top: 30px;
  .outline {
    background: #ffffff;
    color: #e7141a;
  }
}
.special-day-dialog {
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    i {
      margin-left: 10px;
    }
  }
}
.logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.banner-img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .del-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #e7141a;
    font-size: 30px;
    &:hover {
      filter: brightness(80%);
    }
  }
}

.tagDialog {
  .tip {
    font-weight: bold;
    font-size: 16px;
    .assist {
      display: inline-block;
      margin-left: 10px;
      font-size: 10px;
      color: #e7141a;
    }
  }
  .selectServe {
    margin: 20px 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .tag {
    margin: 10px 0;
    cursor: pointer;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
